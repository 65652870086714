<template>
  <div class="wrap mx-auto">
    <h2 class="font-bold text-center page-title mb-3">
      WORLD CLOCK
    </h2>
    <ul class="card_ font-bold ml-0 mb-0">
      <li v-for="(location, index) in locations" :key="index"
        :class="['flex justify-between', index % 2 === 0 ? 'bg-black text-white' : 'bg-white']">
        <div class="card-left w-50 flex flex-col justify-center content-center">
          <h3 class="location w-full mb-0">
            {{ location.name }}
          </h3>
          <h4 class="date w-full italic mb-0">
            {{ location.date }}
          </h4>
        </div>
        <div class="card-right text-right w-1/2">
          <h6 class="time mb-0">
            {{ location.time }}
          </h6>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import '@/css/clock.scss';

export default {
  data () {
    return {
      locations: [
        { name: "NEW YORK", date: "", time: "" },
        { name: "LONDON", date: "", time: "" },
        { name: "BANGKOK", date: "", time: "" },
        { name: "SHANGHAI", date: "", time: "" },
        { name: "SYDNEY", date: "", time: "" },
      ],
    };
  },
  mounted () {
    this.getTime();
    setInterval(this.getTime, 1000);
  },
  methods: {
    getTime () {
      const timezone = {
        "NEW YORK": "America/New_York",
        LONDON: "Europe/London",
        BANGKOK: "Asia/Bangkok",
        SHANGHAI: "Asia/Shanghai",
        SYDNEY: "Australia/Sydney",
      };

      this.locations.forEach((location) => {
        const { date, time, year } = this.currentTime(timezone[location.name]);
        location.time = time;
        location.date = `${date} ${year}`;
      });
    },
    currentTime (timeZone) {
      const locale = "en-US";
      const options_YYYY = { timeZone, year: "numeric" };
      const options_HHMM = { timeZone, hour12: false, hour: "numeric", minute: "2-digit" };
      const options_MMDD = { timeZone, month: "short", day: "numeric" };

      return {
        year: new Date().toLocaleString(locale, options_YYYY),
        date: new Date().toLocaleString(locale, options_MMDD),
        time: new Date().toLocaleString(locale, options_HHMM),
      };
    },
  },
};
</script>
